/* -------------------------------------------------------------------------------------
   Main Javascript file to init the application

   Global Variables you can use globally

   themeOptions = {
      acf_ready:     true | false
      ajax_url:      "https://domain.de/wp-admin/admin-ajax.php"
      dir:           "https://domain.de/wp-content/themes/themename/"
      file_dir:      "/app/public/wp-content/themes/themename/"
      img_dir:       "https://domain.de/wp-content/themes/themename/assets/images/"​
      site_url:      "https://domain.de"
      use_gutenberg: false
   }

------------------------------------------------------------------------------------- */

// F U N C T I O N S
// import { async } from './functions/index.js';
// import $ from '../functions/cash.js';

document.addEventListener('DOMContentLoaded', initApp);
pageLoad();

async function initApp ()
{
   const scroll    = await import('./modifiers/scroll.js');       scroll.Init();

   // M O D U L E S
   const slider    = await import('./modules/slider.js');         slider.Init();
   const accordion = await import('./modules/accordion.js');      accordion.Init();
   const modal     = await import('./modules/modal.js');          modal.Init();
   // const tabs      = await import('./modules/tabs.js');

   // H T M L - E L E M E N T S
   const header    = await import('./modules/header.js');


   // M O D I F I E R S
   const links     = await import('./modifiers/links.js');
   const icons     = await import('./modifiers/icons.js');
   const acf       = await import('./modifiers/acf.js');          acf.Init();
}

function pageLoad ()
{
   var loader = document.querySelector('.page-loader');
   if ( !loader )
   {
      loader = document.createElement('div');
      loader.classList.add('page-loader', 'control', 'is-loading', 'is-large');
      document.addEventListener('DOMContentLoaded', function ()
      {
         document.body.appendChild(loader);
      });
   }

   window.addEventListener('load', function()
   {
      document.body.classList.add('page-loaded');
      document.body.removeChild(loader);
   });
}
